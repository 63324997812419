import React, { useEffect, useState, useCallback, useRef } from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import cookies from "modules/cookies"
import axios from "axios"
import { useUser } from "modules/hooks/onboarding/user"
import {
  JOBTITLE as jobtitleData,
  REVENUES as revenuesData,
} from "@startse/types"

import {
  Container,
  ContainerInfoUser,
  TextConfirmRegister,
  TextUserName,
  ButtonNotThisPerson,
  ContainerButtonRegister,
  TextButtonRegister,
  AvatarStyled,
  ContainerCheckbox,
  SingleCheckboxStyled,
  TextCheckbox,
  TextCheckboxError,
} from "../FrameLogged/style"

const FrameLogged = () => {
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [checkboxValue, setCheckboxValue] = useState(false)
  const [checkboxError, setCheckboxError] = useState(false)
  const paramsRef = useRef()
  const { getUser } = useUser()

  const handleRegister = useCallback(async () => {
    if (checkboxValue) {
      setLoading(true)
      const bodyFormData = new FormData()
      bodyFormData.append("email", userData?.email)
      bodyFormData.append("firstname", userData?.fullName)
      bodyFormData.append("slug_redirect", paramsRef?.current?.slug_redirect)
      bodyFormData.append("whatts_link", paramsRef?.current?.whatts_link)
      bodyFormData.append("id_material", paramsRef?.current?.id_material)
      bodyFormData.append("cargo_from_list", userData?.jobTitle?.label)
      bodyFormData.append("mobilephone", userData?.mobilePhone)
      bodyFormData.append("redirect_onboarding", "true")
      bodyFormData.append(
        "tamanho_empresa_faturamento",
        userData?.companySize?.label
      )

      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_PLATFORM_URI}/api/ebooks`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      const { redirectUrl } = response ? response.data : {}
      if (redirectUrl) {
        window.top.location.href = redirectUrl
      }
    } else {
      setCheckboxError(true)
    }
    setLoading(false)
  }, [userData, setLoading, setCheckboxError, checkboxValue])

  const handleNotThePerson = async () => {
    cookies.remove("authStartseToken")
    window.location.reload()
  }

  const userIsLoggedIn = useCallback(async () => {
    try {
      if (paramsRef.current?.cookie) {
        const userData = await getUser(paramsRef.current?.cookie)
        userData.jobtitle = jobtitleData.find((jobtitleDataList) =>
          jobtitleDataList.label === userData.jobtitle
            ? jobtitleDataList.value
            : userData.jobtitle
        )

        userData.companySize = revenuesData.find((revenuesDataList) =>
          revenuesDataList.label === userData.revenues
            ? revenuesDataList.value
            : userData.company.revenues
        )

        return {
          fullName: userData.firstName + " " + userData.lastName,
          image: userData.profileImageURL,
          email: userData.email,
          mobilePhone: userData.phone,
          jobTitle: userData.jobtitle,
          companySize: userData.companySize,
        }
      }

      return false
    } catch (err) {
      console.log(err)
    }
  }, [getUser])

  useEffect(() => {
    async function init() {
      async function getQuerys() {
        let params = []
        window.location.search
          .split("?")
          .filter((item) => item !== "")
          .forEach((item) => {
            params = [...params, ...item.split("&")]
          })
        let querys = {}
        params
          .filter((item) => item !== "")
          .forEach((param) => {
            const split = param.split("=")
            querys = {
              ...querys,
              [split[0]]: split[1],
            }
          })

        paramsRef.current = querys
      }

      async function userIsLogged() {
        const response = await userIsLoggedIn()
        if (response !== {}) {
          setUserData(response)
        }
        setLoading(false)
      }

      await getQuerys()
      await userIsLogged()
    }

    init()
  }, [setLoading, userIsLoggedIn])

  return (
    <Container>
      {loading ? (
        <div style={{ margin: "0 auto", marginTop: "50%" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <ContainerInfoUser
            backgroundColor={paramsRef.current?.backgroundColor}
          >
            <TextConfirmRegister textColor={paramsRef.current?.textColor}>
              Receba seu Ebook
            </TextConfirmRegister>
            <AvatarStyled alt="Profile photo" src={userData?.image} />
            <TextUserName textColor={paramsRef.current?.textColor}>
              {userData?.fullName}
            </TextUserName>
            <ButtonNotThisPerson
              textColor={paramsRef.current?.textColor}
              onClick={handleNotThePerson}
            >
              Não sou essa pessoa
            </ButtonNotThisPerson>
            <ContainerCheckbox>
              <SingleCheckboxStyled
                name="agree"
                defaultValue={checkboxValue}
                onClick={() => setCheckboxValue(!checkboxValue)}
                label={
                  <>
                    <TextCheckbox textColor={paramsRef.current?.textColor}>
                      <span>Eu concordo com a</span>
                      <span
                        style={{
                          color: paramsRef.current?.linksColors
                            ? `#${paramsRef.current?.linksColors}`
                            : "#0000FF",
                          padding: "0px 5px",
                        }}
                        onClick={() => {
                          window.open(
                            "https://docs.startse.com/politica-de-privacidade"
                          )
                        }}
                      >
                        {` Política de Privacidade `}
                      </span>{" "}
                    </TextCheckbox>
                    {checkboxError && (
                      <TextCheckboxError>
                        *É necessário aceitar os termos para conseguir seu ebook
                      </TextCheckboxError>
                    )}
                  </>
                }
              />
            </ContainerCheckbox>
          </ContainerInfoUser>
          <ContainerButtonRegister
            foregroundColor={paramsRef.current?.foregroundColor}
          >
            <TextButtonRegister
              backgroundColor={paramsRef.current?.buttonTextColor}
              onClick={handleRegister}
            >
              RECEBER O E-BOOK GRATUITO
            </TextButtonRegister>
          </ContainerButtonRegister>
        </>
      )}
    </Container>
  )
}

export default FrameLogged
