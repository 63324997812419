import styled from "styled-components"
import Avatar from "@material-ui/core/Avatar"
import SingleCheckbox from "components/Form/Checkbox/SingleCheckbox"

export const Container = styled.div`
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const ContainerInfoUser = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  height: 100vh;
  padding: 16px 32px;
  background-color: ${(props) =>
    props.backgroundColor ? `#${props.backgroundColor}` : "white"};
`

export const TextConfirmRegister = styled.h1`
  font-family: Barlow, sans-serif;
  font-size: 26px;
  text-align: center;
  margin-top: 40px;
  color: ${(props) => (props.textColor ? `#${props.textColor}` : "black")};
`

export const AvatarStyled = styled(Avatar)`
  width: 91px;
  height: 91px;
  margin: 10px;
  margin-top: 40px;
`

export const TextUserName = styled.h1`
  font-family: Barlow, sans-serif;
  font-size: 25px;
  text-align: center;
  margin-top: 10px;
  color: ${(props) => (props.textColor ? `#${props.textColor}` : "black")};
`

export const ButtonNotThisPerson = styled.h1`
  font-family: Barlow, sans-serif;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => (props.textColor ? `#${props.textColor}` : "black")};
`

export const ContainerButtonRegister = styled.div`
  text-align: center;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
  bottom: 0;
  height: 80px;
  margin: 0 auto;
  margin-top: -100px;
  width: 80%;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.foregroundColor ? `#${props.foregroundColor}` : "black"};
`

export const TextButtonRegister = styled.h1`
  font-family: Barlow, sans-serif;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
  color: ${(props) =>
    props.buttonTextColor ? `#${props.buttonTextColor}` : "white"};
  @media screen and (max-width: 450px) {
    font-size: 1.2rem;
  } ;
`

export const ContainerCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`

export const SingleCheckboxStyled = styled(SingleCheckbox)``

export const TextCheckbox = styled.div`
  display: inline;
  font-family: Barlow, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: ${(props) => (props.textColor ? `#${props.textColor}` : "black")};
`

export const TextCheckboxError = styled.h1`
  font-size: 11px;
  color: #ff0000;
`
